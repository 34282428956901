import { Inject, Injectable } from '@angular/core';
import { GtdNgWidgetComponentAbstract, WidgetsService } from 'gtd-ng-widget';
import { Subscription } from 'rxjs';
import { TableFacadeNewComponent } from '../../components/facade/table-facade/table-facade.component';
import { RefreshDirective } from '../../directives/refresh/refresh.directive';
import { IDataService } from '../data/data-service.interface';
import { PageServiceInterface } from './page-service.interface';

@Injectable({
  providedIn: 'root',
})
export class PageService implements PageServiceInterface {
  private appRefresh: any = {};
  private refreshSubscription: Subscription;

  constructor(@Inject('IDataService') private dataService: IDataService, private widgetsService: WidgetsService) {}

  refresh() {
    /**
     * Update widgets + cards/tables
     */
    const batchedWidgetsData: GtdNgWidgetComponentAbstract[] = [];
    for (const i of Object.keys(this.appRefresh)) {
      const refreshDirective = this.appRefresh[i];
      if (refreshDirective instanceof RefreshDirective) {
        const component: any = refreshDirective.component();
        if (component instanceof GtdNgWidgetComponentAbstract) {
          if (refreshDirective.gtdRefresh) {
            if (!component.isLoading) {
              component.getData();
            }
          } else {
            batchedWidgetsData.push(component);
            component.setIsLoading(true);
          }
        } else {
          component?.update();
        }
      }
    }
    if (batchedWidgetsData.length) {
      this.widgetsService.dataBatched(batchedWidgetsData.map((widget) => widget.getConfig())).subscribe((data) => {
        if (data) {
          for (const i of Object.keys(batchedWidgetsData)) {
            batchedWidgetsData[i].setData(data[i]);
            batchedWidgetsData[i].setIsLoading(false);
          }
        }
      });
    }

    this.refreshSubscription?.unsubscribe();
    this.refreshSubscription = this.dataService.getDataFromServer().subscribe((data) => {
      this.dataService.setData(data);
    });
  }

  refreshOneByOne() {
    /**
     * Update widgets + tables
     */
    for (const i of Object.keys(this.appRefresh)) {
      const component: any = this.appRefresh[i].component();
      if (component instanceof GtdNgWidgetComponentAbstract) {
        component.getData();
      }
      if (component instanceof TableFacadeNewComponent) {
        component.update();
      }
    }
  }

  push(key, data: RefreshDirective) {
    this.appRefresh[key] = data;
  }

  remove(key) {
    delete this.appRefresh[key];
  }
}
